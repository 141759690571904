import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")!
  const _component_BaseButtons = _resolveComponent("BaseButtons")!
  const _component_LightStyle = _resolveComponent("LightStyle")!
  const _component_BackgroundStyle = _resolveComponent("BackgroundStyle")!
  const _component_ColorStyle = _resolveComponent("ColorStyle")!
  const _component_ActiveStyle = _resolveComponent("ActiveStyle")!
  const _component_ActiveLightStyle = _resolveComponent("ActiveLightStyle")!
  const _component_ActiveColorStyle = _resolveComponent("ActiveColorStyle")!
  const _component_Icons = _resolveComponent("Icons")!
  const _component_SocialButtons = _resolveComponent("SocialButtons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_BaseButtons),
      _createVNode(_component_LightStyle),
      _createVNode(_component_BackgroundStyle),
      _createVNode(_component_ColorStyle),
      _createVNode(_component_ActiveStyle),
      _createVNode(_component_ActiveLightStyle),
      _createVNode(_component_ActiveColorStyle),
      _createVNode(_component_Icons),
      _createVNode(_component_SocialButtons)
    ])
  ]))
}